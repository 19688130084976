import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { Law } from '@/api/types/model/Law';
import { PaginationReq } from '@/api/types/model/PaginationReq';

export class LawApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Laws';
  }

  public Paginate (paginate?: PaginationReq) {
    return this.doPaginate<Law>({
      path: '',
      method: HttpVerb.GET,
      query: { ...paginate },
    });
  }
}
