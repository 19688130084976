import { useAuthStore } from '@/stores/auth';
import { isCheckinSubdomain } from '@/utils/environment';
import { createWebHistory, createRouter, RouterView, RouterOptions, RouteRecordRaw } from 'vue-router';

const Page404: RouteRecordRaw = {
  path: '/:pathMatch(.*)*',
  name: 'page-404',
  component: () => import('@/pages/page-404.vue'),
  meta: {
    auth: false,
    allowAuth: true,
  },
};

const Maintenance: RouteRecordRaw = {
  path: '/maintenance',
  name: 'maintenance',
  component: () => import('@/pages/maintenance.vue'),
  meta: {
    auth: false,
    allowAuth: true,
  },
};

const AuthRoutes: RouteRecordRaw[] = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/reset-password.vue'),
    meta: {
      auth: false,
    },
  },
  {
    path: '/set-new-password',
    name: 'set-new-password',
    component: () => import('@/pages/set-new-password.vue'),
    props: (route) => ({
      token: route.query.token?.toString(),
    }),
    meta: {
      auth: false,
    },
  },
];

const DefaultRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home-layout',
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/pages/home.vue'),
      },
      {
        path: 'records',
        name: 'records-index',
        component: () => import('@/pages/records/index.vue'),
      },
      {
        path: 'invites',
        name: 'invites-index',
        component: () => import('@/pages/invites/index.vue'),
      },
      {
        path: 'check-ins/:projectId(\\d+)',
        name: 'check-ins-project-id',
        component: () => import('@/pages/check-ins/_projectId.vue'),
        props: (route) => ({
          projectId: parseInt(route.params.projectId.toString()),
        }),
      },
      {
        path: 'companies',
        name: 'companies',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'companies-index',
            component: () => import('@/pages/companies/index.vue'),
          },
          {
            path: ':companyId(\\d+)',
            name: 'companies-company-id',
            component: () => import('@/pages/companies/_companyId.vue'),
            props: (route) => ({
              companyId: parseInt(route.params.companyId.toString()),
            }),
          },
        ],
      },
      {
        path: 'projects',
        name: 'projects',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'projects-index',
            component: () => import('@/pages/projects/index.vue'),
          },
          {
            path: ':projectId(\\d+)',
            name: 'projects-project-id',
            component: () => import('@/pages/projects/_projectId.vue'),
            props: (route) => ({
              projectId: parseInt(route.params.projectId.toString()),
            }),
          },
          {
            path: 'control/:projectId(\\d+)',
            name: 'projects-control-project-id',
            component: () => import('@/pages/projects/control/_projectId.vue'),
            props: (route) => ({
              projectId: parseInt(route.params.projectId.toString()),
            }),
          },
        ],
      },
      {
        path: 'employees',
        name: 'employees',
        component: RouterView,
        children: [
          {
            path: '',
            name: 'employees-index',
            component: () => import('@/pages/employees/index.vue'),
          },
          {
            path: ':employeeId(\\d+)',
            name: 'employees-employee-id',
            component: () => import('@/pages/employees/_employeeId.vue'),
            props: (route) => ({
              employeeId: parseInt(route.params.employeeId.toString()),
            }),
          },
        ],
      },
      {
        path: 'my-company',
        name: 'my-company',
        component: () => import('@/pages/my-company.vue'),
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/pages/reports/index.vue'),
      },
    ],
  },
];

const AdminRoutes: RouteRecordRaw[] = [
  {
    path: '/admin',
    name: 'admin-layout',
    component: () => import('@/layout/admin.vue'),
    children: [
      {
        path: '',
        name: 'admin-index',
        component: () => import('@/pages/admin/index.vue'),
        meta: {
          admin: true,
        },
      },
      {
        path: 'translations',
        name: 'admin-translations',
        component: () => import('@/pages/admin/translations.vue'),
        meta: {
          admin: true,
        },
      },
      {
        path: 'audit',
        name: 'admin-audit',
        component: () => import('@/pages/admin/audit.vue'),
        meta: {
          admin: true,
        },
      },
      {
        path: 'add-contractor',
        name: 'admin-add-contractor',
        component: () => import('@/pages/admin/add-contractor.vue'),
        meta: {
          admin: true,
        },
      },
      {
        path: 'encryption',
        name: 'admin-encryption',
        component: () => import('@/pages/admin/encryption.vue'),
        meta: {
          admin: true,
        },
      },
    ],
  },
];

const MiscRoutes: RouteRecordRaw[] = [
  {
    path: '/invite/:inviteCode',
    props: true,
    name: 'invite-invite-code',
    component: () => import('@/pages/invite/_inviteCode.vue'),
    meta: {
      auth: false,
      allowAuth: true,
    },
  },
  {
    path: '/print-qr/:projectId(\\d+)',
    name: 'print-qr-project-id',
    component: () => import('@/pages/print-qr/_projectId.vue'),
    props: (route) => ({
      projectId: parseInt(route.params.projectId.toString()),
    }),
  },
];

const CheckinRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'checkin-layout',
    component: () => import('@/layout/check-in.vue'),
    children: [
      {
        path: ':projectId(\\d+)/:colleague?',
        name: 'checkin-project-id-colleague',
        component: () => import('@/pages/checkin/index.vue'),
        props: (route) => ({
          projectId: parseInt(route.params.projectId.toString()),
          colleague: !!route.params.colleague,
        }),
        meta: {
          auth: false,
          allowAuth: true,
        },
      },
      {
        path: 'login/:token/:projectId(\\d+)',
        name: 'checkin-login-token-project-id',
        component: () => import('@/pages/checkin/login.vue'),
        props: (route) => ({
          token: route.params.token,
          projectId: parseInt(route.params.projectId.toString()),
        }),
        meta: {
          auth: false,
          allowAuth: true,
        },
      },
      {
        path: 'done',
        name: 'done',
        component: () => import('@/pages/checkin/done.vue'),
        meta: {
          auth: false,
          allowAuth: true,
        },
      },
    ],
  },
  Maintenance,
  Page404,
];

const Routes: RouteRecordRaw[] = [
  ...AuthRoutes,
  ...DefaultRoutes,
  ...AdminRoutes,
  ...MiscRoutes,
  Maintenance,
  Page404,
];

const router = createRouter({
  history: createWebHistory(),
  routes: isCheckinSubdomain() ? CheckinRoutes : Routes,
} as RouterOptions);

router.beforeEach((to, from, next) => {
  // Default always require auth
  const nonAuth = to.meta?.auth === false;
  const adminOnly = to.meta?.admin;
  const allowAuth = to.meta?.allowAuth;
  const authStore = useAuthStore();
  if (!nonAuth && !authStore.isAuthenticated) {
    const redirect = ['/', '/login'].some(e => e == to.fullPath) ? undefined : to.fullPath;
    next({
      path: '/login',
      query: { redirect: redirect },
    });
  } else if (nonAuth && authStore.isAuthenticated && !allowAuth) {
    next('/');
  } else if (adminOnly && !authStore.isAdmin) {
    next('/');
  } else {
    next();
  }
});

export default router;
