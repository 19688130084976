import { ApiBase } from '@/api/apis/ApiBase';
import { HttpVerb } from '@/api/types/enum/Http';
import { Perspective } from '@/api/types/enum/Perspective';
import { QuestionnaireTree } from '@/api/types/model/QuestionnaireTree';

export class QuestionnaireApi extends ApiBase {
  protected setRouteGroup (): string {
    return 'Questionnaires';
  }

  public Get (lawId: number, perspective: Perspective, language: string) {
    return this.doReq<QuestionnaireTree>({
      path: `${lawId}/${perspective}/${language}`,
      method: HttpVerb.GET,
    });
  }
}